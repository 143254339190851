<template>
  <div :class="`${prefixCls}-care`" id="aochenCare">
    <Separate title="领导关怀" desc="LEADERSHIP CARE" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div :class="`${prefixCls}-care-main`">
      <div :class="`${prefixCls}-care-main-item`" v-for="item in leadershipCare.topLeaderShipCare" :key="item.id">
        <el-image :src="item.picturePath" :class="`${prefixCls}-care-main-item-img`" :alt="item.title" fit="cover"></el-image>
        <div :class="`${prefixCls}-care-main-item-text`">
          <p>- {{item.title}} -</p>
          <div>{{item.remark}}</div>
        </div>
      </div>
    </div>
    <div :class="`${prefixCls}-care-year`">
      <div :class="`${prefixCls}-care-year-item`" v-for="(item, key) in careYearList" :key="item.id">
        <div :class="`${prefixCls}-care-year-item-title`">
          {{key}}
        </div>
        <ul>
          <li v-for="unit in item" :key="unit.id">{{unit.title}} {{unit.remark}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
export default {
  name: 'AochenCare',
  mixins: [WowMixin],
  data () {
    return {
      prefixCls: this.$prefix + '-company',
      careYearList: {}
    }
  },
  props: {
    leadershipCare: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  created () {
    this.setYearList()
  },
  methods: {
    setYearList () {
      this.leadershipCare.years.forEach(year => {
        const list = this.leadershipCare.topLeaderShipCare.filter(item => {
          return item.eventDate.slice(0, 4) === year
        })
        this.careYearList[year + '年度'] = list
      })
    }
  },
  components: {
    Separate
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -company-care;
  .#{$prefixCls}{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 800px;
    &-main{
      display: grid;
      grid-template-columns: repeat(3, 30%);
      grid-gap: 50px 20px;
      padding-bottom: 40px;
      justify-content: space-around;
      border-bottom: 1px solid #EDEDED;
      width: 100%;
      &-item {
        display: flex;
        flex-direction: column;
        &-text{
          display: flex;
          flex-direction: column;
          align-items: center;
          p {
            display: flex;
            flex-direction: row;
            align-items: center;
            line-height: 1.5em;
            margin-bottom: 0;
          }
          div{
            width: 80%;
          }
        }
        &-img {
          width: 100%;
          height: 230px;
        }
      }
    }
    &-year{
      margin: 40px 0;
      text-align: left;
      width: 800px;
      &-item{
        width: 100%;
        padding-bottom: 20px;
        &-title{
          font-size: 20px;
          margin-bottom: 20px;
          font-weight: bold;
        }
        ul {
          padding-left: 0px;
          li {
            font-size: 14px;
            line-height: 2em;
            color: #7F7F7F;
            padding-left: 8px;
            list-style: disc inside;
          }
        }
      }
    }
  }
</style>
